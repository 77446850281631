
const Jobs = [
	{
		category: "Developement",
		objects: [
			{
				title: 'Game Developer',
				subtitle: 'Godot 4.0 Developer',
				description: 'Collaborate with game designers, artists, and other developers using the Godot game engine',
				image: 'https://previews.123rf.com/images/chagin/chagin1211/chagin121100120/16113318-business-people-working-together-a-diverse-work-group.jpg',
				avatar: 'https://gitlab.bunfan.com/uploads/-/system/group/avatar/6/temp_icon.png?width=64'
			},
			{
				title: 'Discord Bot Developer',
				subtitle: 'Node.js Developer',
				description: 'Use Node.js to develop bot for our Discord community. using the latest Discord.js API',
				image: 'https://previews.123rf.com/images/chagin/chagin1211/chagin121100120/16113318-business-people-working-together-a-diverse-work-group.jpg',
				avatar: 'https://gitlab.bunfan.com/uploads/-/system/group/avatar/6/temp_icon.png?width=64'
			},
		]
	},
	{
		category: "Production",
		objects: [
			{
				title: 'Musician / Sound Designer',
				subtitle: 'Composer and Audio Engineer',
				description: 'Collaborate with game designers, artists, and other developers using the Godot game engine',
				image: 'https://previews.123rf.com/images/chagin/chagin1211/chagin121100120/16113318-business-people-working-together-a-diverse-work-group.jpg',
				avatar: 'https://gitlab.bunfan.com/uploads/-/system/group/avatar/6/temp_icon.png?width=64'
			},
		]
	},
	{
		category: "Advertising",
		objects: [
			{
				title: 'Graphic Designer',
				subtitle: 'Adobe Illustrator / Photoshop',
				description: 'Use Node.js to develop bot for our Discord community. using the latest Discord.js API',
				image: 'https://previews.123rf.com/images/chagin/chagin1211/chagin121100120/16113318-business-people-working-together-a-diverse-work-group.jpg',
				avatar: 'https://gitlab.bunfan.com/uploads/-/system/group/avatar/6/temp_icon.png?width=64'
			},
			{
				title: 'Video Editor',
				subtitle: 'Motion Desginer',
				description: 'Do motion design.',
				image: 'https://previews.123rf.com/images/chagin/chagin1211/chagin121100120/16113318-business-people-working-together-a-diverse-work-group.jpg',
				avatar: 'https://gitlab.bunfan.com/uploads/-/system/group/avatar/6/temp_icon.png?width=64'
			},
		]
	},

]


export default Jobs
