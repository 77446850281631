import React, { useState, useContext, useEffect } from "react";
import Layout from "components/Layout";
import Header from "components/Header";
import Seo from "components/seo";
import Jobs from "lib/jobs";

import {
	Stack,
	Avatar,
	IconButton,
	Divider,
	Grid,
	Button,
	Container,
	Card,
	CardHeader,
	CardMedia,
	CardContent,
	CardActions,
	Typography,
} from "@mui/material";

import BunfanTextLogo from "assets/logos/bunfan-text-logo.jpg";

const JobsCard = ({ job }) => {
	return (
		<Card>
			<CardHeader
				avatar={<Avatar src={job.avatar} aria-label="recipe" />}
				action={
					<IconButton aria-label="settings">
						{/* <MoreVertIcon /> */}
					</IconButton>
				}
				title={<Typography variant="h5">{job.title}</Typography>}
				subheader={job.subtitle}
			/>
			<CardActions>
				<Button size="medium">Learn more</Button>
			</CardActions>
		</Card>
	);
};

const JobsCategory = ({ jobObject }) => {
	return (
		<Stack>
			<Typography variant="h4" style={{ marginBottom: "20px" }}>
				{jobObject.category}
			</Typography>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center",
					gap: "5px",
				}}
			>
				{jobObject.objects.map((job) => (
					<JobsCard job={job} />
				))}
			</div>
		</Stack>
	);
};

const Index = () => (
	<Layout>
		<Header />
		<Seo
			title="Staff"
			description="We are the BunFan Games Core Development Team."
			image={BunfanTextLogo}
		/>
		<Container maxWidth="xl">
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "left",
					alignItems: "center",
					flexDirection: "column",
					gap: "25px",
				}}
			>
				{Jobs.map((jobObject) => (
					<JobsCategory jobObject={jobObject} />
				))}
			</div>
		</Container>
	</Layout>
);

export default Index;
